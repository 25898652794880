<div class="card mx-auto max-w-400">
  <div class="card-header">
    <div class="f-20">{{'Buy index' | lang}}</div>
  </div>
  <div class="card-body mt-3 f-16">
    <div class="row">
      <div class="col theme-form">
        <div class="row mb-2 form-group">
          <div class="col">
            <form [formGroup]="buyForm" class="theme-form" style="color:#495057;">
              <div class="form-control py-2">
                <div class="row d-flex justify-content-between inputField f-16">
                  <div class="p-0 ms-3" style="width: 10px;"> <span>$</span></div>
                  <input class="col ps-1 pe-2 py-0 input-extended border-0" type="text" id="buyInput" [(ngModel)]="usdAmount" [readonly]="isCodeUsed"
                    formControlName="usdInput" (keydown)="inputEnterHandler($event, usdAmount)">
                  <label class="col-auto ps-0 c-green text-uppercase cursor-pointer" (click)="setBalanceToAmount()">
                    {{'buttons.all' | lang}}</label>
                </div>
              </div>
            </form>
          </div>
          <label class="text-danger" *ngIf="elemIsInvalid(buyForm.controls['usdInput'])">{{textErrorStr(buyForm.controls.usdInput, floatNumberPattern)}}</label>
        </div>
        <div class="row f-14">
          <label class="col c-gray-light">{{'Available:'|lang}} <label class="c-green">${{balance()}}</label></label>
          <label class="col-auto ps-0 text-uppercase c-green" (click)="showDepositModal()" style="cursor: pointer;">{{'buttons.topUp'|lang}}</label>
        </div>
        <hr>

        <!-- инпут ввода промокода -->
        <div class="row mb-1" [formGroup]="buyForm">
          <div class="mb-2">{{"Promocode" | lang}}</div>
          <div class="col theme-form">
            <input
              id="inputCode"             
              class="form-control"
              type="text"
              formControlName="inputCode"
            >
          </div>
          <div class="col-auto"><button [disabled]="isCodeUsed" class="btn btn-primary" (click)="applyPromocode()">{{"Apply" | lang}}</button></div>
        </div>
        <div *ngIf="elemIsInvalid(inputCode)" class="text text-danger">
          {{ textErrorStr(inputCode, latinAndNumberPattern) | lang }}
        </div>

        <!-- скидка по промокоду -->
        <!-- <div *ngIf="isCodeUsed" class="row mb-3">
          <div>{{"Promocode bonus" | lang}}</div>
          <div class="col-6">{{userPromocode.bonusPercent}}%</div>
          <div *ngIf="tokenAmount == ''" class="col-6 text-end c-green">0</div>
          <div *ngIf="!!tokenAmount" class="col-6 text-end c-green">-{{tokenToUSDT() * (userPromocode.bonusPercent / 100)}}&nbsp;USDT</div>
        <hr>-->
        
        <!-- бонус по промокоду -->
        <div *ngIf="isCodeUsed" class="row mb-3">
          <div class="row f-14">
            <label class="col c-gray-light">{{promoCodeLabel |lang}} {{userPromocode.value? ":" : ""}}
              <label *ngIf="userPromocode.value" class="c-green">${{promocodeTokensUsdt}}</label>
            </label>
          </div>
        </div>
        <hr>
        
        <!-- индекс -->
        <div class="row mb-3">
          <div>{{indexFeeModel.title | lang}}</div>
          <div class="col-6">{{getTokenAmount()}}</div>
          <div class="col-6 text-end">${{tokenToUSDT()}}&nbsp;</div>
        </div>
        <hr>
        
        <!-- entry fee -->
        <div class="row mb-3">
          <div>{{'Entry fee' | lang}}</div>
          <div class="col-6">{{getPercent(getStats().entryFee)}}%</div>
          <div class="col-6 text-end" [ngClass]="{'c-green text-decoration-line-through' : isCodeUsed}">
            ${{calcEntryFee()}}&nbsp;
          </div>
        </div>
        <hr>
        
        <!-- network fee -->
        <div class="row mb-3">
          <div class="col-6">{{transFeeModel.title  | lang}}</div>
          <div class="col-6 text-end" [ngClass]="{'c-green text-decoration-line-through' : isCodeUsed}">
            ${{getTransFeeUsdt(false)}}&nbsp;
          </div>
        </div>
        <hr>

        <!-- total -->
        <div class="row mb-3 text-uppercase f-w-700 f-26 mf-20">
          <div class="col-auto">                  
            {{'Total' | lang}}                          
          </div>
          <div class="col ps-0 text-end">                   
             ${{getTotal()}}
          </div>
        </div>

        <!-- кнопки -->
        <div class="row">
          <div class="col-6">
            <button class="btn btn-outline-primary w-100" type="button" (click)="activeModal.close()">
              {{'buttons.cancel' | lang}}                                
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-primary w-100" type="button" (click)="buy()" [disabled]="buyForm.invalid">
              {{'buttons.buy' | lang}}                                
            </button>
          </div>              
        </div>
      </div>
    </div>        
  </div>   
</div>   
