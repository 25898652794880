export class WalletHistoryTransFilterModel {
  status: number;
  typeCrypto: number; //валюта
  productType: number;
  walletTypeTrans: number; //тип
  startDate?: Date;
  endDate?: Date;
  email: string;
  userId: number;
  fromAdminPanel: boolean = true;
  search: string;
  isShowReferrals: boolean;
}