import { AfterViewInit, Component, ElementRef, Input, OnChanges,
  OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { CustomReactButton } from "./rangoWidget";
import { useWidgetEvents } from "@rango-dev/widget-embedded";
import { BaseComponent } from "../../base/base.component";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { widgetConfig } from "./rangoConfig";

const containerElementRef = "RangoReactIdContainer";

@Component({
  selector: "app-rango-react-component",
  template: `<div #${containerElementRef} class="rango-style"></div>`,
  encapsulation: ViewEncapsulation.None,
})
export class RangoReactWrapperComponent extends BaseComponent implements OnChanges, OnDestroy, AfterViewInit, OnInit {
  @ViewChild(containerElementRef, { static: true }) containerRef!: ElementRef;
  @Input() walletAddress: string = '';
  readyToListen: boolean = true; // флаг определяет можно ли слушать загрузку виджета
  widgetEvents = useWidgetEvents();

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    public activeModal: NgbActiveModal,
    private renderer: Renderer2
  ) {
    super(translateService, modalService);
  }

  ngOnInit(): void {
    var t = this;
    t.setLoading(true);
  }

  onRangoLoad(){
    var t = this;
    widgetConfig.defaultCustomDestinations['ARBITRUM'] = t.walletAddress;
    t.setLoading(false);  
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <React.StrictMode>
        <div>
          <CustomReactButton
            onLoad={this.onRangoLoad.bind(this)}
            config={widgetConfig}
          />
        </div>
      </React.StrictMode>,
      this.containerRef.nativeElement
    );
  }
}