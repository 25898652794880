import { Injectable, EventEmitter, Output } from '@angular/core';
import { HeaderButtonModel } from '../model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private arrIntervals: any = [];
  private funcImpl: any[] = [];
  private arrForLogOut: any = [];
  @Output() LangChangeEvent = new EventEmitter<string>();
  Langchanged(msg: string){
    this.LangChangeEvent.emit(msg);
  } 
  @Output() SeacrhCarouselEvent = new EventEmitter<any>();
  @Output() HeaderButtonEvent = new EventEmitter<string>();
  @Output() HeaderButtonSurveyEvent = new EventEmitter<string>();
  @Output() SubpageEvent = new EventEmitter<HeaderButtonModel>();
  @Output() ShowUploadButtonEvent = new EventEmitter<boolean>();
  @Output() UploadFileEvent = new EventEmitter<any>();
  @Output() SongChangeEvent = new EventEmitter<string>();
  @Output() SongPlayerClose = new EventEmitter();
  constructor() {

  }

  public addFuncToArrayOfIntervals(func: any, interval: number) {
    this.funcImpl.push(func);
    this.arrIntervals.push(setInterval(func, interval))
  }

  public isFuncArrIncludes(func: any){
    return this.funcImpl.includes(func);
  }

  public addFuncToArrayForLogout(func: any) {
    this.arrForLogOut.push(func);
  }

  public logout(){
    this.clearIntervals();
    this.clearModels();
    this.arrIntervals = [];
    this.arrForLogOut = [];
    this.funcImpl = [];
  }
  public clearIntervals() {
    this.arrIntervals.forEach(element => {
      clearInterval(element);
    });
  }

  public clearModels() {
    this.arrForLogOut.forEach(element => {
      try{
        element.clear();
      }
      catch(ex)
      {
        
      }
    });
  }
}
