import { Injectable, HostListener, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { menuitems } from '../constants/menuicons';
import { HeaderButtonModel } from '../model';
import { permissions } from '../constants';

// Menu
export interface Page {
	path?: string;
	title?: string;
	icon?: any;
	image?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Page[];
	roles?: string[];
	header?: string;
	notShowInSidebar?: boolean;
	notShowInMobileNav?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class NavService {

	public screenWidth: any;
	public showNotif: boolean = false;
	public collapseSidebar: boolean = true;
	public collapseHeaderInfo: boolean = false;
	public currentUrl;
	public previousUrl;
	menuitems = menuitems;
	permissions = permissions;

	@Output() toggleSidebarEvent = new EventEmitter();

	constructor() {
		this.onResize();
		if (this.screenWidth < 570) {
			this.collapseSidebar = false;
			this.collapseHeaderInfo = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MainPages: Page[] = [
		{ path: '/overview', title: 'Overview', type: 'link', image: menuitems.dashboard },
		//{ path: '/dashboard', title: 'Dashboard', type: 'link', image: menuitems.dashboard },
		{ path: '/wallet', title: 'Wallet', type: 'link', image: menuitems.wallet },
		{ path: '/all-indexes', title: 'Indexes', type: 'link', notShowInSidebar: false, notShowInMobileNav: false, image: menuitems.indexes },
		{ path: '/index/:id', notShowInSidebar: true, notShowInMobileNav: true },
		{ path: '/verification', title: 'Verification', type: 'link', image: menuitems.verification, header: 'Identity verification', roles: [this.permissions.NotVerified], notShowInMobileNav: true },
		{ path: '/referral/standart', title: 'Invite friends', type: 'link', image: menuitems.referral, notShowInMobileNav: true },
		{ path: '/faq', title: 'FAQ', type: 'link', image: menuitems.faq, notShowInMobileNav: true },
		//{ path: '/documents', title: 'Documents', type: 'link', image: menuitems.documents },
		{ path: '/user-profile/:subpage', title: 'Profile', notShowInSidebar: true, notShowInMobileNav: true },
		{ path: '/notifications', title: 'Notifications', type: 'link', image: menuitems.notifications, notShowInSidebar: true },
	]

	AdminPages: Page[] = [
		{ path: '/admin/dashboard', title: 'Dashboard', type: 'link', image: menuitems.dashboard, roles: [this.permissions.Master] },
		{ path: '/admin/analytics', title: 'Analytics', type: 'link', image: menuitems.wallet, roles: [this.permissions.Master] },
		{ path: '/admin/payments', title: 'Payments', type: 'link', image : menuitems.wallet, roles: [this.permissions.AllUsersMaster] },
		{ path: '/admin/users/users-list', title: 'Users', type: 'link', image: menuitems.users, roles: [this.permissions.Master] },
		{ path: '/admin/user-profile/:id', title: 'User profile', notShowInSidebar: true, roles: [this.permissions.Master] },
		{ path: '/admin/documentation', title: 'Documents', type: 'link', image: menuitems.documents, roles: [this.permissions.Master] },
		{ path: '/admin/settings/settings-fee', title: 'Settings', type: 'link', image: menuitems.settings, roles: [this.permissions.Master] },
		{ path: '/admin/royalty', title: 'Royalty', type: 'link', image: menuitems.royalty, roles: [this.permissions.Master] }
	]

	HEADERBUTTONS: HeaderButtonModel[] = [
		{ itemTitle: 'WalletHistory', buttonTitle: 'History', buttonNumber: 0, className: 'btn-outline-info', needShow: true, url: '/wallet', iconUrl: 'assets/images/ripe/history.svg', funcName: 'ShowHistory()', switcherTitle: 'WalletHistoryBack', textDesktopOnly: true},
		{ itemTitle: 'WalletHistoryBack', buttonTitle: 'Back', buttonNumber: 1, className: 'btn-outline-info', needShow: false, url: '/wallet', funcName: 'ShowHistory()', switcherTitle: 'WalletHistory', textDesktopOnly: false},
		{ itemTitle: 'Materials', buttonTitle: 'Add new', buttonNumber: 0, className: 'btn-primary' },
		{ funcName: 'WithdrawAll()', itemTitle: 'Flow distribution analytics', buttonTitle: 'Withdraw all', headerTitle: "Flow distribution analytics", buttonNumber: 0, className: 'btn-primary', needShow: true },
		{ funcName: 'Back()', itemTitle: 'Profile', buttonTitle: 'Back', headerTitle: "Profile", buttonNumber: 0, className: 'btn-outline-info', needShow: true },
		{ funcName: 'Back()', itemTitle: 'User profile', buttonTitle: 'Back', headerTitle: "User profile", buttonNumber: 0, className: 'btn-outline-info', needShow: true },
		{ itemTitle: 'Documentation', buttonTitle: 'Add new', buttonNumber: 0, url: '/documentation', className: 'btn-primary', needShow: true },
		{ itemTitle: 'Settings', buttonTitle: 'Save', url: '/settings', buttonNumber: 0, className: 'btn-primary px-4', needShow: true },
		{ funcName: 'CreateFeeReport()', itemTitle: 'Analytics', buttonTitle: 'Fee report', url: '/analytics', buttonNumber: 1, className: 'btn-primary px-4', needShow: true },
		{ funcName: 'CreateAnalyticsReport()', itemTitle: 'Analytics', buttonTitle: 'Analytics report', url: '/analytics', buttonNumber: 0, className: 'btn-primary px-4', needShow: true }
	]
	// Array
	mainItems = new BehaviorSubject<Page[]>(this.MainPages);
	adminItems = new BehaviorSubject<Page[]>(this.AdminPages);
	
	/**
	 * Установка начального состояния needShow кнопкам по их itemTitle
	 * @param btnsToShow - названия кнопок, которым нужно проставить значение needShow = true, 
	 * @param btnsToHide - названия кнопок, которым нужно проставить значение needShow = false
	 */
	resetButtonStates(btnsToShow: string[] = null, btnsToHide: string[] = null) {
		let t = this;
		btnsToShow.forEach(btnTitle => {
			// Устанавливаем needShow = true для кнопок, указанных в списке btnsToShow
			let button = t.HEADERBUTTONS.find(x => x.itemTitle === btnTitle);
			if (!!button) button.needShow = true;
		});
	
		btnsToHide.forEach(btnTitle => {
			// Устанавливаем needShow = false для кнопок, указанных в списке btnsToHide
			let button = t.HEADERBUTTONS.find(x => x.itemTitle === btnTitle);
			if (!!button) button.needShow = false;
		}); 
	}
}

