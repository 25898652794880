import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService, Credentials } from "./token.service";
//import * as moment from "moment";
import { tokenRefreshInterval } from "../../configs";
import { ApiService, EventService, StatsService, UserService } from "."
import { tap } from 'rxjs/internal/operators/tap';
import { CodeType } from '../enums/codeType';
import { IResponse } from '../interfaces';
import { CryptoUserService } from './cryptoUser.service';
import { WithdrawBySectionsPostRequest } from '../model';
//import 'rxjs/add/operator/do';
export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

interface AuthData {
  accessToken: string;
  refreshToken: string;
  //expiresAt: Date;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  private static _authData: any;
  public userData: any;
  public showLoader: boolean = false;

  constructor(
    private tokenService: TokenService,
    public router: Router,
    public ngZone: NgZone,
    private api: ApiService,
    public eventService: EventService,
    private cryptoUserService: CryptoUserService,
    private statsService: StatsService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void { }

  get authData() {
    if (!AuthService._authData && localStorage.getItem("auth")) {
      AuthService._authData = JSON.parse(localStorage.getItem("auth"));
    }

    let cookAuth = !!localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) as AuthData : null;
    if (!!AuthService._authData && (!cookAuth || AuthService._authData.refreshToken != cookAuth.refreshToken ||
      AuthService._authData.accessToken != cookAuth.accessToken)) {

      this.SignOut();
      // return;
    }
    return AuthService._authData;
  }

  set authData(newAuth: AuthData) {

    var t = this;
    if (!newAuth) {
      localStorage.removeItem("auth");
    } else {
      // t.cookieService.set("auth", JSON.stringify(newAuth), newAuth["expiresAt"], "/",
      // "",false, "Lax");
      localStorage.setItem("auth", JSON.stringify(newAuth));
    }
    AuthService._authData = newAuth;
  }

  public sendTwoFactor(type: CodeType = CodeType.ConfirmTwoFactor): Promise<IResponse<boolean>> {
    return this.api.get<boolean>('api/cryptoUser/sendTwoFactor/' + type).toPromise();
  }

  //sign in function
  SignIn(credentials: Credentials) {
    return this.tokenService
      .createToken(credentials)
      .pipe(tap((data) => {
        //const expiresAt = moment().add(data["expires_in"], "s").toDate();
        const tokenObj: AuthData = {
          accessToken: data["access_token"],
          refreshToken: data["refresh_token"],
          //expiresAt
        };
        localStorage.setItem("accessToken", tokenObj.accessToken);
        this.authData = tokenObj;
        //this.initRefresh();
      })).toPromise();

    // return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    //   .then((result) => {
    //     if (result.user.emailVerified !== true) {
    //       this.SetUserData(result.user);
    //       this.SendVerificationMail();
    //       this.showLoader = true;
    //     } else {
    //       this.showLoader = false;
    //       this.ngZone.run(() => {
    //         this.router.navigate(['/auth/login']);
    //       });
    //     }
    //   }).catch((error) => {
    //     this.toster.error('You have enter Wrong Email or Password.');
    //   })
  }


  // public initRefresh() {
  //   if (!this.isLoggedIn) {
  //     return;
  //   }


  //   // Условие проверяющее сколько времени осталось у токена.
  //   // Если меньше 15 минут то выбрасываем на страницу логина
  //   // Если больше 15 минут то продолжаем
  //   const expiresIn = moment(this.authData.expiresAt).diff(moment(), "ms");
  //   if (expiresIn < tokenRefreshInterval) {
  //     this.SignOut();
  //     return;
  //   }

    // Observable
    //   .interval(tokenRefreshInterval)
    //   .mergeMap(() => this.tokenService.refreshToken(this.authData.refreshToken))
    //   .retry(2)
    //   .subscribe(
    //     (response) => {
    //       const expiresAt = moment().add(response["expires_in"], "s").toDate();
    //       const tokenObj: AuthData = {
    //         accessToken: response["access_token"],
    //         refreshToken: response["refresh_token"],
    //         expiresAt
    //       };
    //       this.authData = tokenObj;
    //     },
    //     (error) => {

    //       this.SignOut();
    //     }
    //   );

  //}






  ForgotPassword(passwordResetEmail) {
    return;
    // return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }

  //Authentication for Login
  AuthLogin(provider) {
    return;
    // return this.afAuth.auth.signInWithPopup(provider)
    //   .then((result) => {
    //     this.ngZone.run(() => {
    //       this.router.navigate(['/wallet']);
    //     });
    //     this.SetUserData(result.user);
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }



  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    window.stop();
    this.showLoader = false;
    this.authData = undefined;
    this.cryptoUserService.clear();
    this.userService.clear();
    this.statsService.clear();
    this.eventService.logout();
    //localStorage.clear(); //сбрасывает язык в localStorage (по дефолту 'en')
    this.router.navigate(['/auth/login']);
  }

  get isLoggedIn(): boolean {
    return !!this.authData;
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

}
