import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy  } from "@angular/core";
import { ClipboardService } from 'ngx-clipboard';
import { BaseComponent } from '../../base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { DepositTabId, depositModalTabs } from 'src/app/shared/component/modals/deposit-modal/depositModalTabs';
import { DepositTabModel } from 'src/app/shared/model/depositTabModel';
import { RangoReactWrapperComponent } from '../../react/rango-widget/rangoWidgetWrapper';

@Component({
  selector: 'app-deposit-modal',
  templateUrl: "deposit.modal.html",
  styleUrls: ["./deposit.modal.scss"]
})
export class DepositModal extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('qrCode') qrCode: ElementRef;
  @Input() walletAddress: string = '';
  qrCodeInterval: any;
  showQRCode: boolean = false;
  modalRef: NgbModalRef;
  
  DepositTabId = DepositTabId;
  tabs = depositModalTabs;
  currentMainTab: DepositTabModel;
  currentSubTab: DepositTabModel;

  constructor(
    public activeModal: NgbActiveModal,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private modalService: NgbModal
    ) {
      super(translate, modalService)
  }

  async ngOnInit() {
    let t = this;
    t.createQrCode(t);
    //По-умолчанию выбираем крипто-депозит, тк *пока* фиата нет
    t.currentMainTab = t.tabs.find(x => x.id == DepositTabId.CRYPTO_TAB_ID);
    t.currentMainTab.active = true;
    t.setSubTabActive(
      t.currentMainTab.subtabs.find(
        x => x.id == DepositTabId.CRYTO_DEPOSIT_TAB_ID
      )
    );
  }

  public setMainTabActive(newTab: DepositTabModel){
    var t = this;

    t.currentMainTab.active = false; // делаем старую вкладку неактивной
    newTab.active = true; 
    t.currentMainTab = newTab; 
    if(!!t.currentSubTab) { t.currentSubTab.active = false;}
    t.currentSubTab = null; // обнуляем подвкладку
  }

  public setSubTabActive(newTab: DepositTabModel){
    var t = this;

    if(!!t.currentMainTab) { t.currentMainTab.active = false;}
    if(!!t.currentSubTab) { t.currentSubTab.active = false;}
    t.tabs.forEach(tab => {
      if(tab.subtabs.includes(newTab)){
        t.currentMainTab = tab; 
        t.currentMainTab.active = true;
        return;
      }
    }); 
    newTab.active = true; 
    t.currentSubTab = newTab;
  
    // дополнительные обработчики вкладок
    switch(newTab.id){
      case DepositTabId.SWAP_TAB_ID: {
        t.modalRef = t.modalService.open(RangoReactWrapperComponent,
          {
            backdropClass: 'light-white-backdrop',
            centered: true,        
            size: 'sm',
            modalDialogClass:'w-auto',
            windowClass: 'super-modal-delete-users very-nice-shadow'
          }
        );
        t.modalRef.componentInstance.walletAddress = t.walletAddress;
        t.activeModal.close();
        break;
      }
    }
  }

  public copyText(text: string) {
    var t = this;
    t.clipboardService.copy(text);
    t.showSuccess("Copied successfully", "SYSTEM");
  }

  public createQrCode(t: any = this) {
    t.setLoading(true);
    if (!t.qrCode) {
      setTimeout(t.createQrCode, 100, t);
      return;
    }
    
    try {
      let qrCodeSize = window.innerWidth <= 425 ? 290 : 330;
      const qrCodeImage = '/assets/images/ripe/usdt-arbitrum-logo.svg';
      const qrCode = t.getQrCodeStylingOptions(qrCodeSize, qrCodeSize, t.walletAddress, qrCodeImage);
      qrCode.append(t.qrCode.nativeElement);
      let image = new Image();
      image.src = qrCodeImage;
      image.onload = () => {
        t.qrCodeInterval = setInterval(() => {
          if (!!qrCode) {
            qrCode.update({ image: image.src });
            t.showQRCode = true;
            clearInterval(t.qrCodeInterval);
            setTimeout(() => {
              t.setLoading(false);
            }, 500);
          }
        }, 100);
      };
      image.onerror = () => {
        t.showQRCode = true;
        t.setLoading(false);
      }
    }
    catch {
      t.setLoading(false);
    }
  }
  
  ngOnDestroy() {
    let t = this;

    if(!!t.currentMainTab) { t.currentMainTab.active = false;}
    if(!!t.currentSubTab) { t.currentSubTab.active = false;}
    if (!!t.qrCodeInterval) clearInterval(t.qrCodeInterval);
  }
}