
<div class="page-sidebar h-100" id="pages-sidebar" >
  <div class="sidebar custom-scrollba h-100" id="sidebar" #sidebar>
    <div class="d-flex justify-content-left mb-2">
      <img class="cursor-pointer" src="assets/images/ripe/burger.svg" (click)="collapseSidebar()"/>
    </div>

    <div class="desktop-show d-flex flex-column justify-content-between" style="height: calc(100% - 20px)">
      <div [hidden]="navService.showNotif">
        <ul class="nav nav-pills d-flex flex-column flex-nowrap" style="height: calc(100% - 42px);">
          <li *ngFor="let menuItem of menuItems" class="nav-item" [hidden]="needHidden(menuItem.roles, menuItem.title)">
            <a [routerLink]="!menuItem.type ? null : [menuItem.path]" class="nav-link p-0" (click)="toggletNavActive(menuItem)" 
               *ngIf="menuItem.type === 'link' && !menuItem.notShowInSidebar">
              <div [ngClass]="{'active-icon': menuItem.active === true}" class="d-flex align-items-center menu-item">
                <div [ngClass]="{'active-image': menuItem.active === true}" class="title-hidden text-nowrap">
                  <img src="{{menuItem.image}}" class="filter-white float-left" height="24px" width="24px">
                  <span class="menu-title-container">
                    <span class="ps-3 green-transition">{{menuItem.title| translate}}</span>
                  </span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- sidebar footer <p class="sidebar-footer footer-copyright mb-0 f-12 f-w-600 l-h-150">© {{currentYear}} Ripe Tech Corp. All Rights Reserved.</p> -->
    </div>
    

    <div class="mobile-show h-100 d-flex flex-column">
      <div class="w-100 pt-4">
        <div (click)="toProfile()" class="f-20 l-h-120 f-w-600 text-uppercase mb-3" style="word-break: break-word;">
          <img class="avatar-circle-70" src="{{getAvatar()}}">
          <div class="mt-3">{{getUserName()}} </div>
        </div>
  
        <div class="mb-3">
          <!-- <a class="btn btn-secondary d-flex justify-content-between mb-2" href="{{externalUrls.TgCommunityLink}}" target="_blank" 
            style="padding: 6px 12px; max-width: 114px;">
            <label class="l-h-150 f-12 f-w-600">{{'How it works' | lang}}</label> 
            <img src="assets/images/ripe/right-icon-white.svg">
          </a> -->
          <a class="btn btn-secondary d-flex justify-content-between" href="{{externalUrls.TgCommunityLink}}" target="_blank" 
            style="padding: 6px 12px; max-width: 114px;">
            <label class="l-h-150 f-12 f-w-600">{{'buttons.community' | lang}}</label> 
            <img src="assets/images/ripe/telegram-logo.svg">
          </a>
        </div>
  
        <div class="d-flex">
          <div class="me-3">
            <img class="cursor-pointer" src="assets/images/ripe/settings.svg" (click)="toSettings()">
          </div>
          <div class="">
            <img class="cursor-pointer" src="assets/images/ripe/logout-gray.svg" (click)="authService.SignOut()">
          </div>
        </div>
  
        <hr class="my-3">
  
        <div class="d-flex flex-column">
          <div *ngFor="let menuItem of menuItems" class="nav-item cursor-pointer" [hidden]="needHidden(menuItem.roles, menuItem.title)">
            <a 
              [routerLink]="!menuItem.type ? null : [menuItem.path]" 
              class="nav-link p-0" 
              (click)="toggletNavActive(menuItem)"
              *ngIf="menuItem.type === 'link' && menuItem.notShowInMobileNav"
            >
              <div class="d-flex align-items-center" style="height: 28px;">
                <div class="d-flex align-items-center justify-content-center filter-white">
                  <img src="{{menuItem.image}}" width="fit-content" height="fit-content">
                </div>
                <span class="menu-title-container">
                  <span class="ps-3 green-transition">{{menuItem.title| translate}}</span>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div class="w-100 pb-4 pt-5"> 
        <div class="d-flex flex-column theme-form">
          <a *ngFor="let type of existingTypes" href="{{docUrl(type)}}" target="_blank" class="terms-of-use-link c-white text-decoration-none f-12 align-top mb-2">
            {{docNames.get(type)}}
          </a>
        </div>
        <div class="f-12 f-w-600 l-h-150 c-gray-semi-light" style="text-wrap: balance;">
          © {{currentYear}} Ripe Tech Corp. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</div>   


<!--mobile bottom nav-->
<div class="mobile-show fixed-bottom mobile-bottom-nav">
  <div class="nav-pills d-flex" id="sidebarForMobile">
    <div *ngFor="let menuItem of menuItems" class="nav-item"
      [hidden]="needHidden(menuItem.roles, menuItem.title) || menuItem.notShowInMobileNav">
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" class="nav-link p-0" (click)="toggletNavActive(menuItem)" 
         *ngIf="menuItem.type === 'link'">
        <div class="d-flex align-items-center flex-column">
          <div>
            <img src="{{menuItem.image}}" [ngClass]="{'filter-white': !menuItem.active}" height="35px" width="35px">
          </div>
          <div [ngClass]="{'c-green': menuItem.active === true}">
            <div class="f-10 l-h-150 f-w-400"> {{ menuItem.title | lang }} </div>
          </div>
        </div>
      </a>
    </div>
    <!-- Кнопка открытия чатры -->
    <div class="nav-item cursor-pointer">
      <a
        class="nav-link p-0" 
        (click)="openChat()"
      >
        <div class="d-flex align-items-center flex-column" style="height: 28px;">
          <div>
            <img src="assets/images/ripe/menuitems/chat.svg" height="35px" width="35px">
          </div>
          <div>
            <div class="f-10 l-h-150 f-w-400"> {{ 'Chat' | lang }} </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
      <!--
      <hr class="mobile-show">
  
      <div class="d-grid gap-3 align-items-center mobile-show" style="grid-auto-flow: column;"> 
        <div (click)="toProfile()" class="f-20 lh-120 text-uppercase" [ngClass]="{ 'cropped-text' : getUserName().length > 20 }">
          <img class="avatar-circle-36 me-2 mb-1" src="{{getAvatar()}}">
          {{getUserName()}}      
        </div>    
        <div (click)="showNotifications()" class="w-auto text-end">
          <img src="{{headerNotification.notCheckedCount > 0 ? 'assets/images/ripe/bell.svg' : 'assets/images/ripe/bell-unread.svg'}}" class="cursor-pointer">           
        </div>
        <div class="w-auto text-end">
          <img class="cursor-pointer" src="assets/images/ripe/settings.svg" (click)="toSettings()">
        </div>
        <div class="w-auto text-end">
          <img class="cursor-pointer" src="assets/images/ripe/logout-gray.svg" (click)="authService.SignOut()">
        </div>
      </div>    
    </div>-->
  
    <!--
    <div [hidden]="!navService.">
      <div class="d-grid gap-3 align-items-center mobile-show" style="grid-auto-flow: column;">
        <ul class="notification-dropdown custom-scrollbar w-100"
        style="max-height: 272px; overflow: hidden scroll; bottom: 30px; ">
        <li *ngFor="let notif of headerNotification.notifications">
          <div class="media" (click)="checkNotification(notif)">
            <div class="media-body">
              <div class="row my-0">
                <div class="col-auto pe-2 pt-1">
                  <img src="assets/images/ripe/notification.svg">
                </div>
                <div class="col">
                  <div class="f-20 c-green">
                    {{notif.title}}
                  </div>                
                  <p *ngFor="let bodyText of notif.body" class="mb-0" [innerHtml]="bodyText"></p>                
                </div>
              </div> 
            </div>
          </div>
          <hr class="w-100">
        </li>
        <li *ngIf="!headerNotification.notifications || headerNotification.notifications.length == 0" class="txt-dark">
          {{'No new notifications'| lang}}
        </li>
        </ul>
      </div>
      <div *ngIf="headerNotification.notCheckedCount > 0">
        <button type="button" (click)="checkAllNotifications()" class="btn btn-outline-info mb-3 w-100">
          {{'buttons.checkAllNotifications' | lang}}
        </button>
      </div>
      
    </div>  
      -->
      
    <!-- <span class="sidebar-footer desktop-show">© Ripe {{currentYear}}</span> -->




